import React, { useState } from "react";
import { Modal } from "antd";
import ProfilDinas from "../../assets/img/Profil-dinas.png";

const ModalDinas = ({ children }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="profil-title" onClick={() => setOpen(true)}>
        {children}
      </div>
      <Modal
        title="Profil Dinas"
        className="modal-content"
        open={open}
        footer={null}
        width={1000}
        centered={true}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      >
        <div className="img-modal">
          <img src={ProfilDinas} alt="Profil Dinas" />
        </div>
      </Modal>
    </>
  );
};

export default ModalDinas;
