import React from "react";
import "../../assets/css/Welcome.css";
import { useNavigate } from "react-router-dom";
import NavWelcome from "../../components/NavWelcome/Navv";

const Welcome = () => {
  const navigate = useNavigate();
  return (
    <>
      <NavWelcome />
      <div className="bg-welcome">
        <div className="container wrapper-content">
          <div className="btn-isi-data">
            <button className="blink" onClick={() => navigate("/asal")}>
              <span> Isi Buku tamu</span>
              <i className="bi bi-box-arrow-in-right"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
