import React, { useState } from "react";
import { Select } from "antd";
import "../../assets/css/FormInput.css";
import axios from "axios";
import SuccesModal from "../../components/Modal/SuccesModal";
import WarningModal from "../../components/Modal/WarningModal";
import Nav from "../../components/Nav/Nav";

const FormInput = () => {
  const [succes, setSucces] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [showHide, setShowHide] = useState("");
  const [hideKeperluan, setHideKeperluan] = useState("");
  const [hideStatus, setHideStatus] = useState("");
  const [hideNip, setHideNip] = useState("");
  const [input, setInput] = useState({
    nama: "",
    noHp: "",
    nip: " ",
    statusPegawai: "",
    alamatAsalInstansi: "",
    bidang: "",
    jabatan: "",
    keperluan: "",
    tujuan: "",
  });

  const handleInput = (e) => {
    let data = e.target.name;
    let value = e.target.value;

    if (data === "nama") {
      setInput({ ...input, nama: value });
    } else if (data === "noHp") {
      setInput({ ...input, noHp: value });
    } else if (data === "nip") {
      setInput({ ...input, nip: value });
    } else if (data === "statusPegawai") {
      setInput({ ...input, statusPegawai: value });
    } else if (data === "alamatAsalInstansi") {
      setInput({ ...input, alamatAsalInstansi: value });
    } else if (data === "bidang") {
      setInput({ ...input, bidang: value });
    } else if (data === "jabatan") {
      setInput({ ...input, jabatan: value });
    } else if (data === "keperluan") {
      setInput({ ...input, keperluan: value });
    } else if (data === "tujuan") {
      setInput({ ...input, tujuan: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let {
      nama,
      noHp,
      nip,
      statusPegawai,
      alamatAsalInstansi,
      bidang,
      jabatan,
      tujuan,
      keperluan,
    } = input;

    axios
      .post(`${process.env.REACT_APP_URL}/tamus`, {
        nama,
        noHp,
        nip,
        statusPegawai,
        alamatAsalInstansi,
        bidang,
        jabatan,
        tujuan,
        keperluan,
      })
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setSucces(true);
        }
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.status === 400) {
          setFailed(true);
        }
      })
      .finally(() => {
        setLoading(false);
        setInput({
          nama: "",
          noHp: "",
          statusPegawai: "",
          alamatAsalInstansi: "",
          bidang: "",
          jabatan: "",
          keperluan: "",
          tujuan: "",
          nip: "",
        });
      });
  };

  const validateInput = (e) => {
    e.target.setCustomValidity("Silahkan Ketik Nama anda");
  };
  const onInputValidate = (e) => {
    e.target.setCustomValidity("");
  };

  return (
    <>
      <Nav />
      <div className="bg-input">
        <div className="container wrapper-content">
          <form onSubmit={handleSubmit}>
            <div className="wrapper-input">
              <div className="wrapper-outline">
                <div className="title-form">
                  <i className="bi bi-list"></i>
                  <h5>Silahkan Isi form Data</h5>
                </div>
                <div className="row">
                  <div className="col-lg-6 label-title">
                    <label htmlFor="">
                      Nama<small>*</small>
                    </label>
                    <div className="input-form">
                      <input
                        name="nama"
                        value={input.nama}
                        onChange={handleInput}
                        type="text"
                        placeholder="Ketik Nama Anda"
                        onInvalid={validateInput}
                        onInput={onInputValidate}
                        required={true}
                        autoComplete="off"
                      />
                    </div>
                    <label htmlFor="">
                      NoHp<small>*</small>
                    </label>
                    <div className="input-form">
                      <input
                        onChange={handleInput}
                        name="noHp"
                        value={input.noHp}
                        type="number"
                        placeholder="Silahkan Isi"
                      />
                    </div>
                    <label htmlFor="">
                      Status Pegawai<small>*</small>
                    </label>
                    <div className="input-form">
                      <Select
                        onChange={(value) => {
                          setInput({ ...input, statusPegawai: value });
                          setShowHide(value);
                          setHideStatus(value);
                          setHideNip(value);
                        }}
                        defaultValue={"...."}
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                          fontFamily: "Poppins",
                        }}
                        bordered={false}
                        options={[
                          {
                            value: "Asn",
                            label: "Asn",
                          },
                          {
                            value: "Non Asn(Honorer)",
                            label: "Non Asn(Honorer)",
                          },
                          {
                            value: "Lainnya",
                            label: "Lainnya",
                          },
                        ]}
                      />
                    </div>
                    {hideNip === "Asn" && (
                      <>
                        <label htmlFor="">
                          NIP<small>*</small>
                        </label>
                        <div className="input-form">
                          <input
                            type="number"
                            name="nip"
                            placeholder="Silahkan Input NIP anda"
                            onChange={handleInput}
                          />
                        </div>
                      </>
                    )}
                    {hideStatus === "Lainnya" && (
                      <>
                        <label htmlFor=""></label>
                        <div className="input-form">
                          <input
                            type="text"
                            name="statusPegawai"
                            placeholder="Silahkan Input status pegawai anda"
                            onChange={handleInput}
                          />
                        </div>
                      </>
                    )}
                    <label htmlFor="">
                      Alamat/Asal Instansi<small>*</small>
                    </label>
                    <div className="input-form">
                      <textarea
                        name="alamatAsalInstansi"
                        onChange={handleInput}
                        value={input.alamatAsalInstansi}
                        type="text"
                        placeholder="Silahkan Input"
                      />
                    </div>
                    {showHide === "Asn" || showHide === "Non Asn(Honorer)" ? (
                      <>
                        <label htmlFor="bidang">
                          Bidang<small>*(Wajib Diisi)</small>
                        </label>
                        <div className="input-form">
                          <input
                            type="text"
                            id="bidang"
                            placeholder="Silahkan Input"
                            name="bidang"
                            value={input.bidang}
                            onChange={handleInput}
                          />
                        </div>

                        <label htmlFor="">
                          Jabatan<small>*(Wajib Diisi)</small>
                        </label>
                        <div className="input-form">
                          <input
                            type="text"
                            name="jabatan"
                            placeholder="Silahkan Input"
                            value={input.jabatan}
                            onChange={handleInput}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="col-lg-6 label-title">
                    <label htmlFor="">
                      Tujuan<small>*</small>
                    </label>
                    <div className="input-form">
                      <Select
                        onChange={(value) => {
                          setInput({ ...input, tujuan: value });
                        }}
                        defaultValue="..."
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                        }}
                        bordered={false}
                        options={[
                          {
                            value: "Kepala Dinas",
                            label: "Kepala Dinas",
                          },
                          {
                            value: "Sekretaris Dinas",
                            label: "Sekretaris Dinas",
                          },
                          {
                            value: "Bidang Aptika",
                            label: "Bidang Aptika",
                          },
                          {
                            value: "Bidang Informasi Kom.Publik",
                            label: "Bidang Informasi Kom.Publik",
                          },
                          {
                            value: "Bidang Statistik",
                            label: "Bidang Statistik",
                          },
                          {
                            value: "Bidang Persandian",
                            label: "Bidang Persandian",
                          },
                        ]}
                      />
                    </div>
                    <label htmlFor="">
                      Keperluan<small>*</small>
                    </label>
                    <div className="input-form">
                      <Select
                        onChange={(value) => {
                          setInput({ ...input, keperluan: value });
                          setHideKeperluan(value);
                        }}
                        defaultValue="..."
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                        }}
                        bordered={false}
                        options={[
                          {
                            value: "Penelitian Dan Pelaporan",
                            label: "Penelitian Dan Pelaporan",
                          },
                          {
                            value: "Konsultasi Dan Koordinasi",
                            label: "Konsultasi Dan Koordinasi",
                          },
                          {
                            value: "Kunjungan Atau Wisata Arsip",
                            label: "Kunjungan Atau Wisata Arsip",
                          },
                          {
                            value: "Bimbingan Teknis",
                            label: "Bimbingan Teknis",
                          },
                          {
                            value: "Umum Atau Lain-Lain",
                            label: "Umum Atau Lain-Lain",
                          },
                        ]}
                      />
                    </div>
                    {hideKeperluan === "Umum Atau Lain-Lain" && (
                      <>
                        <label htmlFor="">
                          Jika Umum/Lainnya Silahkan Input<small>*</small>
                        </label>
                        <div className="input-form">
                          <textarea
                            type="text"
                            placeholder="Silahkan Input"
                            onChange={handleInput}
                            name="keperluan"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="submit-btn">
                  <button type="submit">submit</button>
                </div>
              </div>
            </div>
          </form>
          {loading && (
            <div className="loading-overlay">
              <div className="spinner-border text-danger" role="status"></div>
            </div>
          )}
          <SuccesModal setSucces={setSucces} succes={succes} />
          <WarningModal setFailed={setFailed} failed={failed} />
        </div>
      </div>
    </>
  );
};

export default FormInput;
