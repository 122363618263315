import React from "react";
// import img12 from "../../assets/img/12.png";
// import img7 from "../../assets/img/7.png";
// import imgBb from "../../assets/img/bb.png";
import "../../assets/css/Footer.css";

const Footer = () => {
  return (
    <>
      <section className="navbar footer">
        <div className="container-fluid">
          <div className="footer-content">
            <small> &copy;{new Date().getFullYear()} : Diskominfo-SP</small>
          </div>
          {/* <div className="d-flex">
            <div className="footer-img">
              <img src={img12} alt="" />
              <img src={img7} alt="" />
              <img src={imgBb} alt="" />
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Footer;
