import React, { useState } from "react";
import { Modal } from "antd";
import "../../assets/css/ModalContent.css";
import VisiMisi from "../../assets/img/Visi-Misi.png";

const ModalVisiMisi = ({ children }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="profil-title" onClick={() => setOpen(true)}>
        {children}
      </div>
      <Modal
        title="Visi-Misi"
        className="modal-content"
        centered={true}
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        width={1000}
      >
        <div className="img-modal">
          <img src={VisiMisi} alt="Visi-Misi" />
        </div>
      </Modal>
    </>
  );
};

export default ModalVisiMisi;
