import React, { useRef, useState } from "react";
import { Modal } from "antd";
import video from "../../assets/vid/video.mp4";
import "../../assets/css/ModalContent.css";

const ModalVideo = ({ children }) => {
  const [open, setOpen] = useState(false);
  const videoRef = useRef(null);

  const closeModal = () => {
    const vid = videoRef.current;
    setOpen(false);

    vid.pause();
  };
  return (
    <>
      <div className="profil-title" onClick={() => setOpen(true)}>
        {children}
      </div>
      <Modal
        title="Profil Kabupaten Banyuasin"
        className="modal-content"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={closeModal}
        footer={null}
        width={1000}
      >
        <div className="vid-welcome">
          <video ref={videoRef} controls src={video} typeof="video/mp4"></video>
        </div>
      </Modal>
    </>
  );
};

export default ModalVideo;
