import React from "react";
import "../../assets/css/Nav.css";

const Nav = () => {
  return (
    <>
      <nav className="navbar bg-nav">
        <div className="container-fluid content-nav">
          <span className="navbar-brand mb-0 h1">E-Buku Tamu</span>
        </div>
      </nav>
    </>
  );
};

export default Nav;
