import React from "react";
import "../../assets/css/Navv.css";
import ModalContent from "../../components/Modal/ModalVideo";
import ModalVisiMisi from "../../components/Modal/ModalVisiMisi";
import ModalDinas from "../../components/Modal/ModalDinas";

const Navv = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-costum bg-navv">
        <div className="container-fluid navv-content">
          <div className="title-nav">
            <a className="navbar-brand" href="/asal">
              E-Buku Tamu
            </a>
          </div>
          <div className="toggler-btn">
            <button
              className="costum-toggler navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <ModalDinas>
                  <div className="nav-link contents-modal">Profil Dinas</div>
                </ModalDinas>
              </li>
              <li className="nav-item ">
                <ModalContent>
                  <div className="nav-link contents-modal">
                    Profil Banyuasin
                  </div>
                </ModalContent>
              </li>
              <li className="nav-item">
                <ModalVisiMisi>
                  <div className="nav-link contents-modal">Visi Dan Misi</div>
                </ModalVisiMisi>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navv;
