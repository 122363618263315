import React, { useState } from "react";
import { Select } from "antd";
import axios from "axios";
import SuccesModal from "../../components/Modal/SuccesModal";
import WarningModal from "../../components/Modal/WarningModal";
import Nav from "../../components/Nav/Nav";

const FormInput = () => {
  const [succes, setSucces] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [hideKeperluan, setHideKeperluan] = useState("");
  const [hideNip, setHideNip] = useState("");
  const [input, setInput] = useState({
    nama: "",
    noHp: "",
    nip: " ",
    statusPegawai: "",
    alamatAsalInstansi: "",
    bidang: "",
    jabatan: "",
    keperluan: "",
    tujuan: "",
  });

  const handleInput = (e) => {
    let data = e.target.name;
    let value = e.target.value;

    if (data === "nama") {
      setInput({ ...input, nama: value });
    } else if (data === "noHp") {
      setInput({ ...input, noHp: value });
    } else if (data === "nip") {
      setInput({ ...input, nip: value });
    } else if (data === "statusPegawai") {
      setInput({ ...input, statusPegawai: value });
    } else if (data === "alamatAsalInstansi") {
      setInput({ ...input, alamatAsalInstansi: value });
    } else if (data === "bidang") {
      setInput({ ...input, bidang: value });
    } else if (data === "jabatan") {
      setInput({ ...input, jabatan: value });
    } else if (data === "keperluan") {
      setInput({ ...input, keperluan: value });
    } else if (data === "detailKeperluan") {
      setInput({ ...input, detailKeperluan: value });
    } else if (data === "tujuan") {
      setInput({ ...input, tujuan: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let {
      nama,
      noHp,
      nip,
      statusPegawai,
      alamatAsalInstansi,
      bidang,
      jabatan,
      tujuan,
      keperluan,
    } = input;

    axios
      .post(`${process.env.REACT_APP_URL}/tamus`, {
        nama,
        noHp,
        nip,
        statusPegawai,
        alamatAsalInstansi,
        bidang,
        jabatan,
        tujuan,
        keperluan,
      })
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setSucces(true);
        }
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.status === 400) {
          setFailed(true);
        }
      })
      .finally(() => {
        setLoading(false);
        setInput({
          nama: "",
          noHp: "",
          statusPegawai: "",
          alamatAsalInstansi: "",
          bidang: "",
          jabatan: "",
          keperluan: "",
          tujuan: "",
        });
      });
  };

  return (
    <>
      <Nav />
      <div className="bg-input">
        <div className="container wrapper-content">
          <form onSubmit={handleSubmit}>
            <div className="wrapper-input">
              <div className="wrapper-outline">
                <div className="title-form">
                  <i className="bi bi-list"></i>
                  <h5>Silahkan Isi form Data</h5>
                </div>
                <div className="row">
                  <div className="col-lg-6 label-title">
                    <label htmlFor="nama">
                      Nama<small>*</small>
                    </label>
                    <div className="input-form">
                      <input
                        id="nama"
                        name="nama"
                        value={input.nama}
                        onChange={handleInput}
                        type="text"
                        placeholder="Ketik Nama Anda"
                        required
                      />
                    </div>
                    <label htmlFor="noHp">
                      NoHp<small>*</small>
                    </label>
                    <div className="input-form">
                      <input
                        onChange={handleInput}
                        name="noHp"
                        id="noHp"
                        value={input.noHp}
                        type="number"
                        placeholder="Silahkan Isi"
                      />
                    </div>
                    <label htmlFor="statusPegawai">
                      Status Pegawai<small>*</small>
                    </label>
                    <div className="input-form">
                      <Select
                        id="statusPegawai"
                        onChange={(value) => {
                          setInput({ ...input, statusPegawai: value });
                          setHideNip(value);
                        }}
                        defaultValue={"...."}
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                        }}
                        bordered={false}
                        options={[
                          {
                            value: "Asn",
                            label: "Asn",
                          },
                          {
                            value: "Non Asn",
                            label: "Non Asn",
                          },
                        ]}
                      />
                    </div>
                    {hideNip === "Asn" && (
                      <>
                        <label htmlFor="">
                          NIP<small>*</small>
                        </label>
                        <div className="input-form">
                          <input
                            type="number"
                            name="nip"
                            placeholder="Silahkan Input NIP anda"
                            onChange={handleInput}
                          />
                        </div>
                      </>
                    )}
                    <label htmlFor="asalInstansi">
                      Asal Instansi<small>*</small>
                    </label>
                    <div className="input-form">
                      <Select
                        id="asalInstansi"
                        onChange={(value) => {
                          setInput({ ...input, alamatAsalInstansi: value });
                        }}
                        bordered={false}
                        defaultValue="......"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                        }}
                        options={[
                          {
                            value: "Sekretariat Daerah",
                            label: "Sekretariat Daerah",
                          },
                          {
                            value: "Sekretariat DPR",
                            label: "Sekretariat DPR",
                          },
                          {
                            value: "Diskominfo-SP",
                            label: "Diskominfo-SP",
                          },

                          {
                            value: "Irkap Banyuasin",
                            label: "Irkap Banyuasin",
                          },
                          {
                            value: "BPBD KESBANGPOL",
                            label: "BPBD KESBANGPOL",
                          },
                          {
                            value: "Badan Pendapatan Daerah",
                            label: "Badan Pendapatan Daerah",
                          },
                          {
                            value: "BPKAD Banyuasin",
                            label: "BPKAD Banyuasin",
                          },
                          {
                            value: "Setwan Banyuasin",
                            label: "Setwan Banyuasin",
                          },
                          {
                            value: "Bapedda & Litbang",
                            label: "Bapedda & Litbang",
                          },
                          {
                            value: "Dinas Perhubungan",
                            label: "Dinas Perhubungan",
                          },
                          {
                            value: "Dinas Perizinan",
                            label: "Dinas Perizinan",
                          },
                          {
                            value: "Dinas Sosial",
                            label: "Dinas Sosial",
                          },
                          {
                            value: "Inspektorat Kabupaten",
                            label: "Inspektorat Kabupaten",
                          },
                          {
                            value: "Dinas Tanaman Pangan dan Holtikultura",
                            label: "Dinas Tanaman Pangan dan Holtikultura",
                          },
                          {
                            value: "Dinas Pertanian",
                            label: "Dinas Pertanian",
                          },
                          {
                            value: "BKPSDM",
                            label: "BKPSDM",
                          },
                          {
                            value: "Dinas PU dan Tata Ruang",
                            label: "Dinas PU dan Tata Ruang",
                          },
                          {
                            value: "Dinas Disperkimtan",
                            label: "Dinas Disperkimtan",
                          },
                          {
                            value: "BPKAD",
                            label: "BPKAD",
                          },
                          {
                            value: "Dinas Ksehatan",
                            label: "Dinas Ksehatan",
                          },

                          {
                            value:
                              "Dinas Pendidikan Pemuda Olahraga dan Pariwisata",
                            label:
                              "Dinas Pendidikan Pemuda Olahraga dan Pariwisata",
                          },
                          {
                            value: "Satpol PP & Damkar",
                            label: "Satpol PP & Damkar",
                          },
                          {
                            value: "Dinas Pertahanan Pangan",
                            label: "Dinas Pertahanan Pangan",
                          },
                          {
                            value: "Dinas Lingkungan Hidup",
                            label: "Dinas Lingkungan Hidup",
                          },
                          {
                            value: "Dinas Kependudukan dan Pencatatan Sipil",
                            label: "Dinas Kependudukan dan Pencatatan Sipil",
                          },
                          {
                            value: "Kecamatan Air Kumbang",
                            label: "Kecamatan Air Kumbang",
                          },

                          {
                            value: "Kecamatan Air Saleh",
                            label: "Kecamatan Air Saleh",
                          },
                          {
                            value: "Kecamatan Banyuasin I",
                            label: "Kecamatan Banyuasin I",
                          },
                          {
                            value: "Kecamatan Banyuasin II",
                            label: "Kecamatan Banyuasin II",
                          },
                          {
                            value: "Kecamatan Banyuasin III",
                            label: "Kecamatan Banyuasin III",
                          },
                          {
                            value: "Kecamatan Betung",
                            label: "Kecamatan Betung",
                          },
                          {
                            value: "Kecamatan Karang Agung Ilir",
                            label: "Kecamatan Karang Agung Ilir",
                          },
                          {
                            value: "Kecamatan Makarti Jaya",
                            label: "Kecamatan Makarti Jaya",
                          },
                          {
                            value: "Kecamatan Muara Padang",
                            label: "Kecamatan Muara Padang",
                          },
                          {
                            value: "Kecamatan Muara Sugihan",
                            label: "Kecamatan Muara Sugihan",
                          },
                          {
                            value: "Kecamatan Muara Telang",
                            label: "Kecamatan Muara Telang",
                          },
                          {
                            value: "Kecamatan Pulau Rimau",
                            label: "Kecamatan Pulau Rimau",
                          },
                          {
                            value: "Kecamatan Rambutan",
                            label: "Kecamatan Rambutan",
                          },
                          {
                            value: "Kecamatan Rantau Bayur",
                            label: "Kecamatan Rantau Bayur",
                          },
                          {
                            value: "Kecamatan Sembawa",
                            label: "Kecamatan Sembawa",
                          },
                          {
                            value: "Kecamatan Selat Penungguan",
                            label: "Kecamatan Selat Penungguan",
                          },
                          {
                            value: "Kecamatan Suak Tapeh",
                            label: "Kecamatan Suak Tapeh",
                          },
                          {
                            value: "Kecamatan Sumber Marga Telang",
                            label: "Kecamatan Sumber Marga Telang",
                          },
                          {
                            value: "Kecamatan Talang Kelapa",
                            label: "Kecamatan Talang Kelapa",
                          },
                          {
                            value: "Kecamatan Tanjung Lago",
                            label: "Kecamatan Tanjung Lago",
                          },
                          {
                            value: "Kecamatan Tungkal Ilir",
                            label: "Kecamatan Tungkal Ilir",
                          },
                        ]}
                      />
                    </div>
                    <label htmlFor="bidang">
                      Bidang<small>*(Wajib Diisi)</small>
                    </label>
                    <div className="input-form">
                      <input
                        id="bidang"
                        type="text"
                        placeholder="Silahkan Input"
                        name="bidang"
                        value={input.bidang}
                        onChange={handleInput}
                      />
                    </div>
                    <label htmlFor="jabatan">
                      Jabatan<small>*</small>
                    </label>
                    <div className="input-form">
                      <input
                        id="jabatan"
                        type="text"
                        name="jabatan"
                        placeholder="Silahkan Input"
                        value={input.jabatan}
                        onChange={handleInput}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 label-title">
                    <label htmlFor="tujuan">
                      Tujuan<small>*</small>
                    </label>
                    <div className="input-form">
                      <Select
                        id="tujuan"
                        onChange={(value) => {
                          setInput({ ...input, tujuan: value });
                        }}
                        defaultValue="..."
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                        }}
                        bordered={false}
                        options={[
                          {
                            value: "Kepala Dinas",
                            label: "Kepala Dinas",
                          },
                          {
                            value: "Sekretaris Dinas",
                            label: "Sekretaris Dinas",
                          },
                          {
                            value: "Bidang Aptika",
                            label: "Bidang Aptika",
                          },
                          {
                            value: "Bidang Informasi Kom.Publik",
                            label: "Bidang Informasi Kom.Publik",
                          },
                          {
                            value: "Bidang Statistik",
                            label: "Bidang Statistik",
                          },
                          {
                            value: "Bidang Persandian",
                            label: "Bidang Persandian",
                          },
                        ]}
                      />
                    </div>
                    <label htmlFor="keperluan">
                      Keperluan<small>*</small>
                    </label>
                    <div className="input-form">
                      <Select
                        id="keperluan"
                        onChange={(value) => {
                          setInput({ ...input, keperluan: value });
                          setHideKeperluan(value);
                        }}
                        defaultValue="..."
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                        }}
                        bordered={false}
                        options={[
                          {
                            value: "Penelitian Dan Pelaporan",
                            label: "Penelitian Dan Pelaporan",
                          },
                          {
                            value: "Konsultasi Dan Koordinasi",
                            label: "Konsultasi Dan Koordinasi",
                          },
                          {
                            value: "Kunjungan Atau Wisata Arsip",
                            label: "Kunjungan Atau Wisata Arsip",
                          },
                          {
                            value: "Bimbingan Teknis",
                            label: "Bimbingan Teknis",
                          },
                          {
                            value: "Umum Atau Lain-Lain",
                            label: "Umum Atau Lain-Lain",
                          },
                        ]}
                      />
                    </div>
                    {hideKeperluan === "Umum Atau Lain-Lain" && (
                      <>
                        <label htmlFor="detailKeperluan">
                          Jika Umum/Lainnya Silahkan Input<small>*</small>
                        </label>
                        <div className="input-form">
                          <textarea
                            type="text"
                            id="detailKeperluan"
                            placeholder="Silahkan Input"
                            onChange={handleInput}
                            name="keperluan"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="submit-btn">
                  <button type="submit">submit</button>
                </div>
              </div>
            </div>
          </form>
          {loading && (
            <div className="loading-overlay">
              <div class="spinner-border text-danger" role="status"></div>
            </div>
          )}
          <SuccesModal setSucces={setSucces} succes={succes} />
          <WarningModal setFailed={setFailed} failed={failed} />
        </div>
      </div>
    </>
  );
};

export default FormInput;
