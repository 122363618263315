import React from "react";
import { Button, Modal, Result } from "antd";
import { useNavigate } from "react-router-dom";

const SuccesModal = ({ key, succes, setSucces }) => {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        key="bener"
        centered
        open={succes}
        onOk={() => setSucces(false)}
        onCancel={() => setSucces(false)}
        closable={false}
        footer={[
          <Button key={"test"} type="primary" onClick={() => navigate("/")}>
            Ok
          </Button>,
        ]}
      >
        <Result
          status="success"
          title="Data Tamu Berhasil Dikirim"
          subTitle="Terima Kasih Telah Berkunjung"
        />
      </Modal>
    </>
  );
};

export default SuccesModal;
