import React from "react";
import { Button, Modal, Result } from "antd";

const WarningModal = ({ failed, setFailed }) => {
  return (
    <>
      <Modal
        key={"gagal"}
        centered
        open={failed}
        onOk={() => setFailed(false)}
        onCancel={() => setFailed(false)}
        closable={false}
        footer={[
          <Button
            key={"slh"}
            type="primary"
            onClick={() => window.location.reload(false)}
          >
            Ok
          </Button>,
        ]}
      >
        <Result
          status="error"
          title="Data Tamu Gagal Dikirim"
          subTitle="Silahkan Isi kembali"
        />
      </Modal>
    </>
  );
};

export default WarningModal;
