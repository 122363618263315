import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Welcome from "./pages/Welcome/Welcome";
import FormInput from "./pages/FormInputPemkab/FormInput";
import FormInputLuar from "./pages/FormInputLuar/FormInput";
import Footer from "./components/Footer/Footer";
import Asal from "./pages/Asal/Asal";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/area-pemkab" element={<FormInput />} />
        <Route path="/luar-umum" element={<FormInputLuar />} />
        <Route path="/asal" element={<Asal />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
