import React from "react";
import "../../assets/css/Asal.css";
const Asal = () => {
  return (
    <>
      <div className="bg-asal">
        <div className="container wrapper-content">
          <div className="position-btn">
            <div className="style-btn">
              <a href="/area-pemkab">Area Pemkab</a>
            </div>
            <div className="style-btn">
              <a href="/luar-umum">Luar/Umum</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Asal;
